<template>
  <div>
      <!----- 搜索框 start------->
      <el-form :inline="true" class="search">
          <el-form-item label="项目">
              <el-select v-model="searchData.project_id" placeholder="选择项目" filterable>
                  <el-option
                          v-for="(item, index) in formData.relate.project"
                          :key="index"
                          :label="item.name + '[' + item.id + ']'"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="分组名">
              <el-input v-model="searchData.name" placeholder="分组名筛选" clearable />
          </el-form-item>
          <el-form-item>
              <el-button @click="getGroups()">查询</el-button>
          </el-form-item>

          <el-form-item style="float: right">
              <el-button type="primary" @click.stop="clickCreate()">创建任务</el-button>
          </el-form-item>
      </el-form>
      <!----- 搜索框 end------->

      <!----- 表格 start------->
      <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
          <el-table-column prop="id" label="ID"  />
          <el-table-column prop="project.name" label="项目" />
          <el-table-column prop="name" label="名称" />
          <el-table-column prop="code" label="标识" />
          <el-table-column prop="notes_count" label="笔记总数" />
          <el-table-column prop="created_at" label="创建时间" />
          <el-table-column label="操作">
              <template #default="scope">
                  <el-button type="primary" size="small" @click="clickEdit(scope.row)">修改</el-button>
                  <el-button type="success" size="small" @click="clickShare(scope.row)">协作</el-button>
              </template>
          </el-table-column>
      </el-table>
      <!----- 表格 end------->

      <!----- 分页 start------->
      <div class="cherry-page" v-show="pageData.total">
          <el-pagination
                  small
                  background
                  layout="prev, pager, next, total"
                  :total="pageData.total"
                  v-model:current-page="searchData.page"
                  @update:current-page="getGroups()"
                  class="mt-4"
          />
      </div>
      <!----- 分页 end------->

      <!-------- 弹出框表单 start ---------->
      <el-dialog v-model="formData.show" title="编辑分组/批次" fullscreen append-to-body>
          <el-form
                  ref="note-group"
                  :rules="formData.rules"
                  label-width="100px"
                  :model="formData.data"

          >
              <el-form-item label="关联项目" prop="project_id">
                  <el-select v-model="formData.data.project_id" placeholder="选择项目" filterable>
                      <el-option
                              v-for="(item, index) in formData.relate.project"
                              :key="index"
                              :label="item.name + '[' + item.id + ']'"
                              :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>

              <el-form-item label="分组名称" prop="name">
                  <el-input v-model="formData.data.name" placeholder="项目名称"/>
              </el-form-item>
          </el-form>

          <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog()">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
          </template>
      </el-dialog>
      <!-------- 弹出框表单 end ---------->

      <!-------- 弹出框表单 start ---------->
      <el-dialog v-model="shareData.show" title="邀请协作" fullscreen append-to-body>
          <el-form
              ref="note-share"
              :rules="shareData.rules"
              label-width="100px"
              :model="shareData.data"

          >
              <el-form-item label="分组">
                  <el-input v-model="shareData.data.name" disabled placeholder="项目名称"/>
              </el-form-item>
              <el-form-item label="权限" prop="roles">
                  <el-select v-model="shareData.data.roles" style="width:100%" placeholder="选择权限" multiple filterable>
                      <el-option label="查看" value="view"></el-option>
                      <el-option label="创建" value="create"></el-option>
                      <el-option label="编辑" value="edit"></el-option>
                      <el-option label="删除" value="delete"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="有效期" prop="lifetime">
                  <el-select v-model="shareData.data.lifetime" placeholder="选择有效期">
                      <el-option label="1天" :value="86400"></el-option>
                      <el-option label="3天" :value="259200"></el-option>
                      <el-option label="7天" :value="604800"></el-option>
                      <el-option label="15天" :value="1296000"></el-option>
                  </el-select>
              </el-form-item>
          </el-form>

          <div>
              <el-divider />
              <el-input readonly v-model="shareData.url">
                  <template #prepend>链接地址</template>
              </el-input>
          </div>

          <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog(true)">取消</el-button>
                <el-button type="primary" @click="onShareSubmit">提交</el-button>
              </span>
          </template>
      </el-dialog>
      <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
  import {apiGetProjects} from '@/api/projectApi'
  import {apiGetNoteGroups, apiCreateNoteGroup, apiUpdateNoteGroup, apiShareNoteGroup} from '@/api/noteApi'

  const from = {
      id: 0,
      name: '',
      project_id: '',
  }

  const share_form = {
      id: 0,
      name: '',
      roles: [],
      lifetime: 259200,
  }
  export default {
      name: 'note.group',
      created() {
          this.getGroups()
          this.getProject()
      },
      data () {
          return {
              formData: {
                  show: false,
                  data: from,
                  rules: {},
                  relate: {
                      project: []
                  }
              },
              shareData: {
                  url: '',
                  data : share_form,
                  rules: {
                      roles: [{required: true, message: '请选择权限', trigger: 'blur'}],
                      lifetime: [{required: true, message: '请选择有效期', trigger: 'blur'}],
                  },
                  show: false,
              },
              tableData: [],
              searchData: {
                  project_id: '',
                  name: '',
                  page: 1,
                  with_query: ['project'],
              },
              pageData: {
                  total: 0,
              }
          }
      },
      methods: {
          // 获取项目
          getProject(force = false) {
              if (this.formData.relate.project.length && !force) {
                  return
              }

              apiGetProjects({per_page: 200}).then(res => {
                  this.formData.relate.project = res.data
              })
          },
          getGroups() {
              apiGetNoteGroups(this.searchData).then(data => {
                  this.tableData = data.data
                  this.pageData = data.meta
              })
          },
          clickShare(row) {
              this.shareData.data.id = row.id
              this.shareData.data.name = row.name
              this.showDialog(true)
          },
          clickCreate () {
              this.showDialog()
          },
          clickEdit (row) {
              this.formData.data = row
              this.showDialog()
          },
          onShareSubmit () {
              this.$refs['note-share'].validate(valid => {
                  if (valid) {
                      apiShareNoteGroup(this.shareData.data.id, this.shareData.data).then(data => {
                          this.$message.success('生成成功')
                          this.shareData.data = share_form
                          this.shareData.url = window.location.protocol +
                              "//" +
                              window.location.host +
                              '/share/note/' + data.share_code
                      })
                  }
              })

          },
          onSubmit() {
            this.$refs['note-group'].validate( (valid) => {
                if (valid) {

                  if (this.formData.data.id) {
                      apiUpdateNoteGroup(this.formData.data.id, this.formData.data).then(() => {
                          this.$message.success('修改成功')
                          this.getGroups()
                          this.closeDialog()
                      })
                  } else {
                      apiCreateNoteGroup(this.formData.data).then(() => {
                          this.$message.success('创建成功')
                          this.getGroups()
                          this.closeDialog()
                      })
                  }

                }
            })
          },
          showDialog (share = false) {
              if (share) {
                  this.shareData.show = true
              } else {
                  this.formData.show = true
              }
          },
          closeDialog (share = false) {
              if (share) {
                  this.shareData.show = false
              } else {
                  this.formData.show = false
              }
          }
      }
  }
</script>
