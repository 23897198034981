import {httpGet, httpPost, httpPut} from '@/plugins/request'

/**
 * 项目列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjects(query = [])
{
    return httpGet('/manage/project', query)
}

/**
 * 创建项目
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateProject(data = [])
{
    return httpPost('/manage/project', data)
}

/**
 * 修改项目
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiPutProject(id, query = [])
{
    return httpPut(`/manage/project/${id}`, query)
}

