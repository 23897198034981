import {httpGet, httpPost, httpPut} from '@/plugins/request'
import {getApiUrl} from '@/plugins/common'
import {getUser} from '@/plugins/auth'


/**
 * 获取笔记列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetNotes(query = [])
{
    return httpGet('/manage/note', query)
}


/**
 * 创建笔记
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateNote(data = [])
{
    return httpPost('/manage/note', data)
}


/**
 * 修改笔记
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateNote(id, data = [])
{
    return httpPut(`/manage/note/${id}`, data)
}

/**
 * 导出
 *
 * @param query
 * @returns {string}
 */
export function apiExportNote(query = []) {
    let userinfo = getUser()

    query.token = userinfo.access_token

    return getApiUrl('manage/note/data/export', query)
}


/**
 * 获取笔记组列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetNoteGroups(query = [])
{
    return httpGet('/manage/note-group', query)
}


/**
 * 创建笔记组
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateNoteGroup(data = [])
{
    return httpPost('/manage/note-group', data)
}


/**
 * 修改笔记组
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateNoteGroup(id, data = [])
{
    return httpPut(`/manage/note-group/${id}`, data)
}

/**
 * 分享
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiShareNoteGroup(id, data = [])
{
    return httpPost(`/manage/note-group/${id}/share`, data)
}
